import axios from 'axios';
import {AuthenticationDetails, CognitoUserPool, CognitoUser, CognitoRefreshToken} from 'amazon-cognito-identity-js';
import config from '../config';
import ErrorCodes from '../constants/error-codes';
import store from '../store';
import interceptor from "@/plugins/interceptor";

class AuthService {
    login(user) {
        const self = this;
        return new Promise((resolve, reject) => {
            self._authenticateCognito(user)
                .then((userParameters) => {
                    return self._authenticateApi(userParameters);
                })
                .then((userParameters) => {
                    resolve(userParameters);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    cognitoLogin(user) {
        const self = this;
        return new Promise((resolve, reject) => {
            self._authenticateCognito(user)
                .then((userParameters) => {
                    resolve(userParameters);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    changePassword(user) {
        const self = this;
        return new Promise((resolve, reject) => {
            const cognitoUser = self._getCognitoUser(user);
            self._authenticateCognito(user)// authenticate once again in case session dies
                .then(() => {
                    cognitoUser.changePassword(user.password, user.newPassword, (err) => {
                        if (err) {
                            reject({
                                code: ErrorCodes.COGNITO_FAILED_CHANGING_PASSWORD,
                                message: err.message
                            });
                            return;
                        }
                        resolve({})
                    });
                })
                .catch(() => {
                    reject({
                        code: ErrorCodes.COGNITO_AUTH_FAILURE,
                        message: 'Error during authenticating with existing password.'
                    });
                });
        });
    }

    resetPassword(user) {
        const self = this;
        return new Promise((resolve, reject) => {
            const cognitoUser = self._getCognitoUser(user);
            self._authenticateCognito(user)// authenticate once again in case session dies
                .then(() => {
                    resolve({})
                })
                .catch((err) => {
                    if (err.code === ErrorCodes.COGNITO_REQUIRES_REGISTRATION) {
                        cognitoUser.completeNewPasswordChallenge(user.newPassword, {}, {
                            onSuccess: () => {
                                resolve({});
                            },
                            onFailure: (err2) => {
                                reject({
                                    code: ErrorCodes.COGNITO_FAILED_SETTING_NEW_PASSWORD,
                                    message: err2.message
                                });
                            }
                        });

                    } else {
                        reject({
                            code: ErrorCodes.COGNITO_AUTH_FAILURE,
                            message: 'Error during authenticating with existing password.'
                        });
                    }
                });
        });
    }

    sendForgotPasswordCode(userInput) {
        const cognitoUser = this._getCognitoUser(userInput);
        
        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess: () => {
                    resolve({});
                },
                onFailure: (err) => {
                    err.message = 'Your registration has not been completed. The password cannot be reset in the current state.';
                    reject({
                        code: 44444,
                        message: err.message
                    })
                },
                inputVerificationCode: (data) => {
                    resolve(data);
                }
            });
        });
    }

    setPasswordUsingCode(userInput) {
        const cognitoUser = this._getCognitoUser(userInput);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(userInput.code, userInput.newPassword, {
                onFailure(err) {
                    reject(err);
                },
                onSuccess() {
                    resolve({});
                },
            });
        });
    }

    _authenticateApi(userParameters) {
        return new Promise((resolve, reject) => {
            const clientId = userParameters['custom_client_id'] || '',
                clientSecret = userParameters['custom_client_secret'] || '';

            if (clientId.length === 0 || clientSecret.length === 0) {
                reject({
                    code: ErrorCodes.API_CLIENT_SECRETS_MISSING,
                    message: 'Not allowed to authenticate'
                });
                return;
            }
            delete userParameters['custom_client_id'];
            delete userParameters['custom_client_secret'];

            axios.get(config.Api.url + 'api/v1/authenticate', {headers: {client_id: clientId, client_secret: clientSecret}})
                .then((authResponse) => {
                    if (authResponse.data.status === 'success') {
                        userParameters.access_token = authResponse.data.data.access_token;
                        interceptor.resetInterceptors();
                        interceptor.setAuthenticatedInterceptor(authResponse.data.data.access_token);
                        resolve(userParameters);
                    }
                    reject({
                        code: ErrorCodes.API_AUTH_FAILURE,
                        message: 'Authentication failed'
                    });
                })
                .catch((error) => {
                    reject(error);
                })
        });

    }

    _authenticateCognito(userInput) {
        return new Promise((resolve, reject) => {
            const authenticationDetails = new AuthenticationDetails({
                Username: userInput.username,
                Password: userInput.password,
            });
            const self = this;
            const cognitoUser = this._getCognitoUser(userInput);
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function () {
                    cognitoUser.getUserAttributes(function (err, userData) {
                        if (err) {
                            reject({
                                code: ErrorCodes.COGNITO_GET_ATTRIBUTES_FAILED,
                                message: err.message
                            });
                            return;
                        }
                        resolve(self._arrayToObjectParams(userData));
                    });
                },
                newPasswordRequired: (userAttributes) => {
                    delete userAttributes.email_verified;
                    reject({
                        code: ErrorCodes.COGNITO_REQUIRES_REGISTRATION,
                        message: 'Need to complete registration',
                        userAttributes
                    });
                },
                onFailure: function (err) {
                    reject({
                        code: ErrorCodes.COGNITO_AUTH_FAILURE,
                        message: err.message
                    });
                },
            });
        });
    }

    _getCognitoUser(userInput) {
        let cognitoUser = store.state.auth.cognitoUser;
        
        if (!cognitoUser) {
            const userPool = new CognitoUserPool({
                UserPoolId: config.Auth.userPoolId,
                ClientId: config.Auth.userPoolWebClientId,
                // Storage: window.sessionStorage
            });

            cognitoUser = new CognitoUser({
                Username: userInput.username,
                Pool: userPool,
            });
            store.commit('auth/cognitoUserCreated', cognitoUser);
        }

        return cognitoUser;
    }

    _arrayToObjectParams(attributes) {
        const userParameters = {};
        attributes.forEach((attribute) => {
            userParameters[attribute.Name.replace(':', '_')] = attribute.Value;
        });

        return userParameters;
    }

    logout() {
        interceptor.resetInterceptors();
        store.dispatch('auth/logout');
    }

    getIdTokenFromStorage() {
        const clientId = config.Auth.userPoolWebClientId;
        const storedUserData = store.state.auth.userData;
    
        if (!storedUserData || !storedUserData.sub) {
            console.error("No authenticated user found.");
            return null;
        }
    
        const userSub = storedUserData.sub;
        const storageKey = `CognitoIdentityServiceProvider.${clientId}.${userSub}.idToken`;
    
        const idToken = localStorage.getItem(storageKey);
    
        if (!idToken) {
            console.error("ID Token not found in localStorage.");
            return null;
        }
    
        return idToken;
    }

    refreshSession() {
      return new Promise((resolve, reject) => {
        const userData = store.state.auth.userData;
        if (!userData || !userData.sub) {
          return reject(new Error("No authenticated user found."));
        }
    
        const clientId = config.Auth.userPoolWebClientId;
        const userSub = userData.sub;
        const refreshTokenKey = `CognitoIdentityServiceProvider.${clientId}.${userSub}.refreshToken`;
        const storedRefreshToken = localStorage.getItem(refreshTokenKey);
    
        if (!storedRefreshToken) {
          return reject(new Error("Refresh token not found. Please log in again."));
        }
    
        // Manually create CognitoUser
        const userPool = new CognitoUserPool({
          UserPoolId: config.Auth.userPoolId,
          ClientId: config.Auth.userPoolWebClientId,
        });

        const cognitoUser = new CognitoUser({
          Username: userData.email,
          Pool: userPool,
        });
    
        // Create CognitoRefreshToken object
        const refreshToken = new CognitoRefreshToken({ RefreshToken: storedRefreshToken });
    
        // Force refresh using the manually set token
        cognitoUser.refreshSession(refreshToken, (err, newSession) => {
          if (err) {
            console.error("Session Refresh Failed:", err);
            return reject(new Error("Failed to refresh session. Please log in again."));
          }
    
          // Store the new tokens
          const newIdToken = newSession.getIdToken().getJwtToken();
    
          // Update localStorage
          const idTokenKey = `CognitoIdentityServiceProvider.${clientId}.${userSub}.idToken`;
          localStorage.setItem(idTokenKey, newIdToken);
    
          resolve(newSession);
        });
      });
    }
    
      
      
      
    
}

export default new AuthService();
