import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";
import AuthService from "./auth.service";
import config from "../config";
import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand,
} from "@aws-sdk/client-cognito-identity";

const getCredentials = async () => {
  let idToken = AuthService.getIdTokenFromStorage();
  if (!idToken) {
    throw new Error("No authenticated user found.");
  }

  const tokenPayload = JSON.parse(atob(idToken.split('.')[1]));
  const currentTime = Math.floor(Date.now() / 1000);
  const margin = 60; // refresh if token expires in less than 60 seconds

  if (tokenPayload.exp < currentTime + margin) {
    await AuthService.refreshSession();
    // Get the new token after refresh
    idToken = AuthService.getIdTokenFromStorage();
  }

  const identityClient = new CognitoIdentityClient({
    region: config.Auth.region,
  });
  const getIdCommand = new GetIdCommand({
    IdentityPoolId: config.Auth.identityPoolId,
    Logins: {
      [`cognito-idp.${config.Auth.region}.amazonaws.com/${config.Auth.userPoolId}`]:
        idToken,
    },
  });

  const identityResponse = await identityClient.send(getIdCommand);
  const identityId = identityResponse.IdentityId;

  const getCredentialsCommand = new GetCredentialsForIdentityCommand({
    IdentityId: identityId,
    Logins: {
      [`cognito-idp.${config.Auth.region}.amazonaws.com/${config.Auth.userPoolId}`]:
        idToken,
    },
  });

  const credentialsResponse = await identityClient.send(getCredentialsCommand);
  return credentialsResponse.Credentials;
};

const getClient = async () => {
  let awsCredentials;
  try {
    awsCredentials = await getCredentials();
  } catch (e) {
    throw new Error(e.message)
  }
  const secretsManagerClient = new SecretsManagerClient({
    region: config.Auth.region,
    credentials: {
      accessKeyId: awsCredentials.AccessKeyId,
      secretAccessKey: awsCredentials.SecretKey,
      sessionToken: awsCredentials.SessionToken,
    },
  });

  return secretsManagerClient;
};

const getSecret = async (secretName) => {
  try {
    const client = await getClient();
    const command = new GetSecretValueCommand({ SecretId: secretName });
    const response = await client.send(command);
    return response.SecretString ? JSON.parse(response.SecretString) : null;
  } catch (error) {
    console.error("Error fetching secret:", error);
    return null;
  }
};

export { getSecret, getCredentials, getClient };
