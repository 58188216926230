const config = {
    Auth: {
        region: process.env.VUE_APP_AWS_REGION,
        userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
        userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
        identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID
    },
    Api: {
        url: process.env.VUE_APP_PARTNER_API_URL
    },
    Google: {
        apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        measurementId: process.env.VUE_APP_GOOGLE_MEASUREMENT_ID,
    }
};

export default config;
